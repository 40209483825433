import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stepper",
      "style": {
        "position": "relative"
      }
    }}>{`Stepper`}</h1>
    <p>{`O componente é uma indicação visual do progresso do usuário através de um conjunto de passos. Serve para guiar o usuário através de um conjunto de etapas para completar um processo específico, geralmente um Wizzard.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Apresente os itens sequenciais da esquerda para a direita.`}</li>
      <li parentName="ul">{`Permita que o usuário volte em algum passo caso necessite revisar uma etapa.`}</li>
      <li parentName="ul">{`Sempre que possível utilize alguma indicação do status dos passos do wizard, como quando está completo/validado, se está incompleto/inválido.`}</li>
    </ul>
    <h2 {...{
      "id": "situacao-dos-steps",
      "style": {
        "position": "relative"
      }
    }}>{`Situação dos Steps`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Completo</th>
      <td>Apresentado quando o usuário atingiu o requisito mínimo para prosseguir ao próximo passo.</td>
    </tr>
    <tr>
      <th>Ativo</th>
      <td>Apresentado quando as informações ainda não foram preenchidas e o passo ainda não foi validado.</td>
    </tr>
    <tr>
      <th>Incompleto</th>
      <td>Etapas ainda não atingidas.</td>
    </tr>
  </tbody>
    </table>
    <h2 {...{
      "id": "exemplos",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplos`}</h2>
    <h3 {...{
      "id": "estados-controlados-manualmente",
      "style": {
        "position": "relative"
      }
    }}>{`Estados controlados manualmente`}</h3>
    <p>{`O estado do stepper é controlado manualmente através das propriedades do componente `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Step`}</code>{`.`}</p>
    <Demo src='pages/components/stepper/StepperDefault' mdxType="Demo" />
    <h3 {...{
      "id": "controlando-o-estado-com-o-hook-usestepperstate",
      "style": {
        "position": "relative"
      }
    }}>{`Controlando o estado com o hook `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`useStepperState`}</code></h3>
    <p>{`Opcionalmente, o hook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useStepperState`}</code>{` pode ser usado para controlar o estado do stepper.`}</p>
    <Demo src='pages/components/stepper/StepperHook' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      